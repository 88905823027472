import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import { amenityMultiSelect, contentTypeSelector, minCapacityField, multiSelectRegions, multiSelectSpecials, resortMultiSelector } from "../../../components/utils";
import { resourcesAutocomplete, unitsAutocomplete } from "../../../inputSpecs";

import { WidgetOptions } from "./container.types";
import activitiesAutocomplete from "../../../inputSpecs/activityAutocomplete";
import { getI18nLocaleObject } from "../../../i18n";
import { multiSelectAccoKinds } from "../../mxts/typesearch";
import namespaceList from "../../../i18n/namespaceList";

const TARGETS = ["dynamic-container"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dynamic-container-options",
    name: getI18nLocaleObject(namespaceList.dynamicContainer, "container"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicContainer, "containers"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "preFilteredAvailability",
                                label: getI18nLocaleObject(namespaceList.admin, "preFilteredAvailability"),
                                type: "checkbox",
                            },
                        ],
                        [contentTypeSelector("contentType"), resourcesAutocomplete("resourceId"), unitsAutocomplete("unitId"), activitiesAutocomplete("activityIds")],
                        [
                            amenityMultiSelect("amenities", undefined, true),
                            multiSelectSpecials("specials", undefined, true),
                            multiSelectRegions("regions", undefined, true),
                            resortMultiSelector("resorts", undefined, undefined, { setVisibility: true }),
                            multiSelectAccoKinds("accoKinds", getI18nLocaleObject(namespaceList.widgetTypeSearch, "accoKinds"), true),
                            minCapacityField("minCapacity", undefined, (options) => !!options.preFilteredAvailability),
                            {
                                variable: "minArrivalDate",
                                type: "date",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "minArrivalDate"),
                            },
                            {
                                variable: "maxArrivalDate",
                                type: "date",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "maxArrivalDate"),
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
            ],
        },
    ],
};
