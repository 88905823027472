import * as React from "react";
import * as moment from "moment";

import { ACCOMMODATION_TYPE, RESORT, UNIT, getAmenityById, getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";
import { PageWidgetSpec, isPageWidget, reportWidgetRenderError } from "../../";
import { getI18nLocaleObject, wrapProps } from "../../../i18n";

import { ActionType } from "../../../redux/actions";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { State } from "../../../redux";
import { WidgetGroup } from "../../widget.enum";
import { WidgetOptions } from "./container.types";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import namespaceList from "../../../i18n/namespaceList";
import { widgetOptionsForm } from "./";

interface ContainerBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    options: WidgetOptions;
    context: CMSProviderProperties;
    childs: Array<{ element: JSX.Element; options: any }>;
}

interface ContainerStoreProps {
    dynamicFilter: DynamicFilter;
}

interface ContainerDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction>;
}

interface ContainerProps extends ContainerBaseProps, ContainerStoreProps, ContainerDispatchProps {}

interface ContainerStates {
    disableWidget: boolean;
}

function isValidNumber(str: string) {
    return /^-?\d+(\.\d+)?$/.test(str);
}

class ContainerBase extends React.Component<ContainerProps, ContainerStates> {
    constructor(props: ContainerProps) {
        super(props);
        this.state = {
            disableWidget: true,
        };
        const resorts = props.options?.resorts;
        const amenities = props.options.preFilteredAvailability && props.options?.amenities?.map((amenity) => amenity.value.toString());
        const accoKinds = props.options.preFilteredAvailability && props.options?.accoKinds?.map((accoKind) => +accoKind.value);
        const specialCodes = props.options.preFilteredAvailability && props.options?.specials?.map((special) => special.value);
        const resortIds = resorts && (Array.isArray(resorts) ? resorts.map((resort) => +resort.value) : [resorts]);
        const unitId = props.options?.unitId;
        const regionIds = props.options.preFilteredAvailability && props.options?.regions?.map((region) => +region.value);
        const minArrivalDate = props.options.preFilteredAvailability && props.options?.minArrivalDate && moment(props.options.minArrivalDate).format(DATE_FORMAT.ELASTIC);
        const maxArrivalDate = props.options.preFilteredAvailability && props.options?.maxArrivalDate && moment(props.options.maxArrivalDate).format(DATE_FORMAT.ELASTIC);
        const activityIds = props.options?.activityIds?.map((activityId) => activityId.value);
        const { dispatchAction } = props;
        const minCapacity = props.options.preFilteredAvailability && props.options?.minCapacity;
        const filter: DynamicFilter = {};
        let resources: number[] | number | undefined = props.options?.resourceId;
        if (resources) {
            resources = Array.isArray(resources) ? resources.map((resort) => +resort.value) : [resources];
        }

        if (amenities && amenities.length > 0) {
            const dynamicAmenities = props.dynamicFilter.amenities ? props.dynamicFilter.amenities.filter((am) => amenities.indexOf(am) === -1) : [];
            filter.amenities = [...dynamicAmenities, ...amenities];
        }

        if (specialCodes) {
            filter.specialcode = specialCodes as any;
        }

        if (props?.options?.contentType === UNIT && unitId) {
            filter.unitid = unitId;
        }

        if (props?.options?.contentType === RESORT && resortIds?.length) {
            filter.resortids = resortIds;
        }

        if (regionIds) {
            filter.regionIds = regionIds;
        }
        if (accoKinds) {
            filter.accokindids = accoKinds;
        }
        if (resources && props?.options?.contentType === ACCOMMODATION_TYPE && (resources as number[])?.length) {
            filter.resourceid = resources as number;
        }
        if (minArrivalDate) {
            filter.minimumArrivalDate = minArrivalDate;
        }
        if (maxArrivalDate) {
            filter.maximumArrivalDate = maxArrivalDate;
        }
        if (activityIds?.length) {
            filter.resourceActivityDetailsId = activityIds[0];
        }
        if (minCapacity) {
            filter.minCapacity = minCapacity;
        }
        if (Object.keys(filter).length > 0) {
            const filterType = dynamicFilterType.addPreselectedFilters;
            const payload: DynamicFilter = { ...props.dynamicFilter, ...filter };
            const action = this.getAction(filterType, payload);
            dispatchAction(action);
        }
    }

    public componentDidMount() {
        this.setState({ disableWidget: !isClientLoggedIn() });
    }

    public render(): JSX.Element | null {
        const { childs, options } = this.props;
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        return (
            <div className={`${hideWidget}`} data-testid="container-div">
                {childs.map((child, ind) => (
                    <div key={ind} data-testid="element-div">
                        {child.element}
                    </div>
                ))}
            </div>
        );
    }

    private getAction = (filter: dynamicFilterType, payload: DynamicFilter) => {
        const { dispatchAction } = this.props;
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter,
            payload,
        };
        return action;
    };
}

function mapStateToProps(state: State): ContainerStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction>): ContainerDispatchProps {
    return { dispatchAction: dispatch };
}

export const Container = connect<ContainerStoreProps, ContainerDispatchProps>(mapStateToProps, mapDispatchToProps)(ContainerBase);

const DynamicContainer = wrapProps<ContainerBaseProps>(Container);

export const ContainerWidget: PageWidgetSpec<WidgetOptions> = {
    id: "containerWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.dynamicContainer, "container"),
    description: getI18nLocaleObject(namespaceList.dynamicContainer, "containerDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({}),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        const parentOptions: WidgetOptions | undefined = JSON.parse(JSON.stringify(widget.options));
        if (dynamicContainerOptions && parentOptions) {
            parentOptions.contentType = parentOptions.contentType || dynamicContainerOptions.contentType;
            parentOptions.resourceId = parentOptions.resourceId || dynamicContainerOptions.resourceId;
            parentOptions.styleIds = parentOptions.styleIds || dynamicContainerOptions.styleIds;
            parentOptions.unitId = parentOptions.unitId || dynamicContainerOptions.unitId;
            parentOptions.resortMultiSelector = parentOptions.resortMultiSelector || dynamicContainerOptions.resortMultiSelector;
        }
        const children = await Promise.all(
            widget.children.map((child, index) => {
                const childSpec = child.spec;
                if (isPageWidget(childSpec)) {
                    return childSpec
                        .render(child, context, sitemapWidgetOptions, resultOptions, parentOptions)
                        .then((element) => ({ element, options: child.options }))
                        .catch((err) => {
                            reportWidgetRenderError(widget, err, childSpec, context);
                            return { element: <div key={index} />, options: {} };
                        });
                }
                throw new TypeError("Expected child widgets to be page widget or dynamic widget");
            })
        );
        // it will replace the amenity Id by amenity identifier
        if (widget.options?.amenities?.length) {
            const amenities = await Promise.all(
                widget.options.amenities?.map(async (amenity: any) => {
                    const doesContainAmenitiesId = isValidNumber(amenity.value.toString());
                    if (doesContainAmenitiesId) {
                        const amenities = await getAmenityById(amenity.value, context);
                        amenity.value = amenities.content[0].identifier;
                    }
                    return amenity;
                })
            );
            widget.options.amenities = [...amenities];
        }
        return <DynamicContainer dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} childs={children} />;
    },
};
